<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{singular}} #{{data.id}} <span v-if="data.poId">for Purchase Order #{{data.poId}}</span></h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              class="ml-2"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <span v-if="data.createdAt">Created By {{lookupUsername(data.createdBy)}} at {{utils.formatDate(data.createdAt, 'withTime')}}</span>
          <span v-if="data.sealedAt">Sealed By {{lookupUsername(data.sealedBy)}} at {{utils.formatDate(data.sealedAt, 'withTime')}}</span>
          <span v-if="data.voidedAt">Voided By {{lookupUsername(data.voidedBy)}} at {{utils.formatDate(data.voidedAt, 'withTime')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-btn color="error" @click="openVoidDialog()" :disabled="data.status===-1">Void</v-btn>
            <v-btn class="ml-2" color="success" @click="openSealDialog()" :disabled="!canSeal">Seal</v-btn>
            <v-btn v-if="data.poId" class="ml-2" color="info" @click="$router.push(`/purchaseorders/view/${data.poId}`)">Purchase Order <v-icon>mdi-arrow-right</v-icon></v-btn>
            <div v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_RR')" class="mt-4" style="width: 100%;">
              <dynamicButtonContainer class="mt-4" :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_RR')}`" :data="{status: data.status, id1: $route.params.id}" style="width: 100%;"/>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-2">
          <span>
            <v-autocomplete
                :items="suppliers"
                v-model="data.supplierId"
                item-text="name"
                item-value="id"
                :disabled="!canEdit || (data.poId && data.poId > 0)"
                outlined
                label="Supplier"
                clearable
                @change="updateSupplier"
            />
          </span>
          <span v-if="canEdit && !data.poId" class="d-flex flex-column">
            <h2>Add Item</h2>
            <v-card outlined>
              <v-card-text>
                <span class="d-flex flex-column">
                  <v-text-field :loading="searchLoading" clearable label="Search Products" v-model="productSearchTerm" dense outlined @change="searchProduct"></v-text-field>
                  <v-checkbox @change="updateSupplier" v-if="getGlobalValue('constrainPurchaseOrdersToSupplierItems')!='true'" style="margin-top: -20px;" label="Constrain search to selected supplier" v-model="data.metadata.constrainSearch"/>
                </span>
                <span>
                  <span v-if="productSearchResults.length>0" class="d-flex flex-row justify-center">
                    <b>Search Results</b>
                    <v-btn x-small color="info" style="margin-left: 10px;" @click="clearSearch">Clear</v-btn>
                  </span>
                  <div v-for="(item, i) in productSearchResults" :key="i">
                    <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                        <v-btn :disabled="data.ReceivingReportLineItems.findIndex(x => x.productId===item.id)>=0" :loading="item.loading" x-small fab color="success" @click="addProductToRR(item)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <span  v-if="getGlobalValue('regularPriceFieldOnReceivingReports') && isAllowed('product', 'u')" class="d-flex flex-column align-start">
                        <span>RP: ${{item.regularPrice}}</span>
                      </span>
                      <span class="d-flex flex-row">
                        <router-link v-if="isAllowed('product', 'u')" :to="'/products/view/'+item.id">ID: {{item.id}}</router-link>
                        <span v-else>ID: {{item.id}}</span>
                        <span class="ml-2">SKU:{{item.sku}}</span>
                      </span>
                    </div>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
          <span v-if="canEdit && data.poId && data.PurchaseOrder && data.PurchaseOrder.PurchaseOrderLineItems">
            <h2>PO Items</h2>
            <div v-for="item in data.PurchaseOrder.PurchaseOrderLineItems" :key="item.id">
              <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                <span class="d-flex flex-row justify-space-between">
                  <h3 style="text-align: left;">{{item.productName}}</h3>
                  <v-btn x-small :disabled="data.ReceivingReportLineItems.findIndex(x => x.productId===item.id)>=0" :loading="item.loading" fab color="success" @click="addProductToRR(item)"><v-icon>mdi-plus</v-icon></v-btn>
                </span>
                <span class="d-flex flex-column align-start">
                  <span><b>Qt. Purchased: {{item.quantity}}</b></span>
                  <span><b>Qt. Already Received: {{item.receivedQty|| 0}}</b></span>
                  <!-- <p>{{item}}</p> -->
                  <span>ID: {{item.productId}}, SKU:{{item.sku}}</span>
                </span>
              </div>
            </div>
          </span>
        </v-col>
        <v-col style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">
          <div style="height: 100%;">
            <h2>Line Items</h2>
            <span class="red--text" v-if="getGlobalValue('costPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u') && data.status===0">Cost price can only be modified when a supplier is selected and only the selected supplier's price can be entered through this receiving report. If you wish to enter further information, please go to the product. A cost price of $0 is also created for the chosen supplier on all the chosen products where a cost price is not specified.</span>
            <span class="red--text" v-if="data.status===1">Voiding this report will not revert the regular price and cost price of an item if they were altered. This needs to be changed manually.</span>
            <div style="border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px;">
              <!-- body -->
                <v-simple-table style="background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width:100px;" class="text-left">
                        QTY
                      </th>
                      <th class="text-center">
                        ID
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-center">
                        SKU
                      </th>
                      <th v-if="getGlobalValue('regularPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u')" class="text-left">
                        Regular Price
                      </th>
                      <th v-if="data.supplierId && getGlobalValue('costPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u')" class="text-left">
                        Cost Price
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rrItem, i) in data.ReceivingReportLineItems" :style="i===data.ReceivingReportLineItems.length-1?'border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;':''" :key="i">
                      <td>
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            :rules="quantityRules"
                            v-model="rrItem.quantity"
                            min="1"
                            @change="updateLineItem(rrItem)">
                        </v-text-field>
                      </td>
                      <td class="text-center">
                        <router-link v-if="isAllowed('product', 'u') && rrItem.productId != 'adhoc'" :to="'/products/view/'+rrItem.productId">{{rrItem.productId}}</router-link>
                        <span v-else>{{rrItem.productId}}</span>
                      </td>
                      <td>{{rrItem.name}}</td>
                      <td class="text-center">{{rrItem.sku}}</td>
                      <td v-if="getGlobalValue('regularPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u')">
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            :rules="priceRules"
                            v-model="rrItem.metadata.regularPrice"
                            min="1"
                            @change="updateLineItem(rrItem)">
                        </v-text-field>
                      </td>
                      <td v-if="data.supplierId && getGlobalValue('costPriceFieldOnReceivingReports')==='true' && isAllowed('product', 'u')">
                        <v-text-field
                            outlined
                            class="mt-5"
                            style="width:100px; border-bottom-left-radius: inherit;"
                            dense
                            type="number"
                            :disabled="!canEdit"
                            :rules="priceRules"
                            v-model="rrItem.metadata.costPrice"
                            min="1"
                            @change="updateLineItem(rrItem)">
                        </v-text-field>
                      </td>
                      <td style="border-bottom-right-radius: inherit;">
                        <span v-if="getGlobalValue('productSerialShowOnReceivingReport')==='true'">
                          <v-badge v-if="rrItem.Product?.metadata?.requireSerials && (rrItem.quantity-rrItem.serials?.length)!==0" offset-x="15" offset-y="15" overlap bottom color="error" :content="`${(rrItem.quantity-(rrItem.serials?.length))!==0?rrItem.quantity-(rrItem.serials?.length):''}`">
                            <v-btn class="ml-1 mb-1" @click="openLineItemSerialsDialog(rrItem)" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                          </v-badge>
                          <v-badge v-else-if="(rrItem.quantity-rrItem.serials?.length)!==0" offset-x="15" offset-y="15" overlap :color="rrItem.quantity<rrItem.serials?.length?'error':'success'" :content="`${(rrItem.quantity-(rrItem.serials?.length))!==0?rrItem.quantity-(rrItem.serials?.length):''}`">
                            <v-btn class="ml-1 mb-1" @click="openLineItemSerialsDialog(rrItem)" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                          </v-badge>
                          <v-btn color="success" v-else class="ml-1 mb-1" @click="openLineItemSerialsDialog(rrItem)" x-small fab><v-icon>mdi-barcode</v-icon></v-btn>
                        </span>
                        <v-btn class="ml-3 mb-1" v-if="canEdit" :loading="rrItem.loading" x-small fab color="error" @click="removeLineItem(rrItem.productId)"><v-icon >mdi-close</v-icon></v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <span v-if="getGlobalValue('productSerialShowOnReceivingReport')==='true'" class="d-flex flex-row mt-3 justify-end">
              <span class="d-flex flex-column">
                <h3>{{serialNaming.singular}} Legend</h3>
                <span class="mt-2">
                  <v-badge offset-x="15" offset-y="15" overlap color="error" content="-1">
                    <v-btn class="ml-1 mb-1" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                  </v-badge>
                  <span class="ml-2">Has 1 too many non-required {{serialNaming.pluralLower}}.</span>
                </span>
                <span class="mt-2">
                  <v-badge offset-x="15" offset-y="15" overlap color="success" content="1">
                    <v-btn class="ml-1 mb-1" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                  </v-badge>
                  <span class="ml-2">Missing 1 non-required {{serialNaming.singularLower}}.</span>
                </span>
                <span class="mt-2">
                  <v-badge offset-x="15" offset-y="15" overlap bottom color="error" :content="1">
                    <v-btn class="ml-1 mb-1" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                  </v-badge>
                  <span class="ml-2">Requires 1 more {{serialNaming.singularLower}}.</span>
                </span>
                <span class="mt-2">
                  <v-btn color="success" class="ml-1 mb-1" x-small fab><v-icon>mdi-barcode</v-icon></v-btn>
                  <span class="ml-2">Has all {{serialNaming.pluralLower}}.</span>
                </span>
              </span>
            </span>
          </div>
        </v-col>
        <v-col cols="2">
          <span class="d-flex flex-column ml-3">
            <v-card outlined>
              <v-card-text>
                <v-text-field :loading="supplierInvoiceNumberLoading" label="Supplier Invoice Number" v-model="data.metadata.supplierExternalInvoiceId" dense outlined @change="saveSupplierInvoiceNumber"></v-text-field>
                <v-btn small color="success">Save</v-btn>
              </v-card-text>
            </v-card>
            <v-card outlined>
              <v-card-text>
                <v-textarea class="mt-4" dense name="Notes" label="Notes" v-model="data.metadata.note" outlined ></v-textarea>
                <v-btn small color="success" :loading="noteLoading"  @click="saveNote">Save</v-btn>
              </v-card-text>
            </v-card>
            <v-card outlined class="mt-3">
              <v-card-text>
                <span class="mt-5">
                  <v-file-input
                    outlined
                    dense
                    accept="image/*"
                    name="file"
                    show-size
                    :rules="supplierImageRules"
                    counter
                    label="Upload Invoice File"
                    @change="onAddImg"
                  ></v-file-input>
                  <v-btn :disabled="!imgFile || imgFile.size > parseInt(this.getGlobalValue('VEC_MAX_IMAGE_UPLOAD_SIZE'))" :loading="imageUploadLoader" color="info" block small @click="uploadSupplierInvoiceImage()">Upload</v-btn>
                </span>
                <span v-if="data.metadata && data.metadata.media && data.metadata.media.length > 0" class="mt-3">
                  <div v-for="(img,index) in data.metadata.media" :key="index" class=" mt-2 d-flex flex-row align-space-between justify-space-between" style="border-radius: 7px; border: 1px rgba(0,0,0,0.25) solid;">
                    <span>
                      <a :href="img.url" target="__blank">
                        <img :src="img.url" style="width: 100%; height: 300px; object-fit: contain;" alt="">
                      </a>
                      <v-btn v-if="img.ocrDump" x-small info text @click="openViewOCRDUMPDialog(img.ocrDump)">View Extracted Text</v-btn>
                    </span>
                    <confirmedActionButton style="margin-left: -32px;" color="error" xsmall fab text @cb="deleteSupplierInvoiceImage(img)" fabIcon="mdi-close" :dialogText="'Are you sure you wish to proceed?'" />
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        v-model="viewOCRDUMPDialog.isOpen"
        max-width="8.5in"
    >
      <v-card>
        <v-card-title class="text-h5">
          View Text Extracted From Image
        </v-card-title>
        <v-card-text><pre>{{ viewOCRDUMPDialog.data }}</pre></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeViewOCRDUMPDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="authDialog.sealOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Seal {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptSeal"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="authDialog.voidOpen"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Void {{singular}}
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="authDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="closeAuthDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :loading="this.authDialog.loading"
              :disabled="!this.authDialog.password"
              @click="attemptVoid"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lineItemSerialsDialog.isOpen" width="1000">
      <v-card>
        <v-card-title v-if="lineItemSerialsDialog.data">
          {{serialNaming.plural}} for Product {{lineItemSerialsDialog.data.productId}}
        </v-card-title>
        <v-card-text v-if="lineItemSerialsDialog.data">
          <v-row>
            <v-col v-if="data.status===0" cols="4" style="border-right: 1px solid #eee" class="d-flex flex-column">
              <h3>Add {{serialNaming.singular}}</h3>
              <span class="d-flex flex-row justify-space-between align-center">
                <v-text-field clearable @keydown.enter="addLineItemSerial" :loading="lineItemSerialsDialog.isLoading" v-model="lineItemSerialsDialog.newSerial" outlined hide-details dense :label="`${serialNaming.singular}`"/>
                <v-btn :disabled="(lineItemSerialsDialog.data.serials?.length>=lineItemSerialsDialog.data.quantity)||!lineItemSerialsDialog.newSerial" :loading="lineItemSerialsDialog.isLoading" @click="addLineItemSerial" class="ml-2" color="success" fab x-small><v-icon>mdi-plus</v-icon></v-btn>
              </span>
              <span v-if="lineItemSerialsDialog.data.Product?.metadata?.requireSerials" class="red--text">This product requires all {{serialNaming.pluralLower}} to be entered.</span>
              <span v-if="lineItemSerialsDialog.data.Product?.metadata?.requireSerials && getGlobalValue('replaceProductSerialsWithIMEI')">All {{serialNaming.pluralLower}} must be unique from all other products and itself.</span>
              <span v-if="!lineItemSerialsDialog.data.Product?.metadata?.requireSerials && getGlobalValue('replaceProductSerialsWithIMEI')">This product does not have {{serialNaming.pluralLower}} required but must still be unique if they're included.</span>
            </v-col>
            <v-col>
              <h3>{{serialNaming.plural}} ({{lineItemSerialsDialog.data.serials?.length||0}}/{{lineItemSerialsDialog.data.quantity}})</h3>
              <v-text-field :disabled="lineItemSerialsDialog.data.serials.length===0" :hint="lineItemSerialsDialog.data.serials.length===0?'No items in list.':''" :persistent-hint="lineItemSerialsDialog.data.serials.length===0" dense outlined label="Search List" v-model="lineItemSerialsDialog.search" clearable @input="filterLineItemSerials"/>
              <span class="d-flex flex-column" style="max-height: 500px; overflow-y: auto;">
                <v-card class="mb-2" outlined v-for="(serial, i) of lineItemSerialsDialog.filtered" :key="i">
                  <span class="pa-2 pl-5 d-flex flex-row align-center justify-space-between">
                    <b>{{serial.serial}}</b>
                    <span class="d-flex flex-row">
                      <v-btn v-if="data.status===0" :disabled="lineItemSerialsDialog.isLoading" @click="removeLineItemSerial(serial)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
                      <v-btn v-if="data.status!==0" class="ml-2" @click="$router.push({path: '/products/serial/'+serial.serial})" color="success" fab x-small><v-icon>mdi-history</v-icon></v-btn>
                    </span>
                  </span>
                </v-card>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeLineItemSerialsDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import utils from "../../plugins/helpers";
  import {mapGetters} from 'vuex'
  import confirmedActionButton from './../../components/confirmedActionButton.vue';
  import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
  export default {
    components: {
      confirmedActionButton,
      dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,
        loader: false,
        noteLoading: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        imageUploadLoader: false,
        imgFile: null,
        supplierInvoiceNumberLoading: false,
        supplierImageRules: [
          value => !value || value.size < parseInt(this.getGlobalValue('VEC_MAX_IMAGE_UPLOAD_SIZE')) || `Image size should be less than ${parseInt(this.getGlobalValue('VEC_MAX_IMAGE_UPLOAD_SIZE'))} Bytes`,
        ],

        max25chars: v => v.length <= 25 || 'Input too long!',
        quantityRules: [
          v => v>=1 || 'Must be more than or equal 1'
        ],
        priceRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],

        data: {
          ReceivingReportLineItems: [],
          metadata: {
            constrainSearch: false
          }
        },

        singular: "Receiving Report",
        singularLower: "receivingreport",
        plural: "Receiving Reports",
        pluralLower: "receivingreports",

        suppliers: [],

        authDialog: {
          sealOpen: false,
          voidOpen: false,
          password: '',
          loading: false,
        },

        productSearchResults: [],
        productSearchTerm: "",
        searchLoading: false,
        viewOCRDUMPDialog: {
          isOpen: false,
          data: {}
        },

        removeSerialDialog: {
          isOpen: false,
          isLoading: false,
          data: null
        },

        lineItemSerialsDialog: {
          isOpen: false,
          data: null,
          other: null,
          isLoading: false,
          newSerial: "",
          search: "",
          filtered: []
        },
      }
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      supplierDisabledCriteria(){
        return this.data.ReceivingReportLineItems.length>0
      },
      selectedSupplier(){
        if(this.suppliers.length>0){
          return this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
        }
        return "-"
      },
      canSeal(){
        if(this.data.status!==0) return false;
        if(!this.data.ReceivingReportLineItems || this.data.ReceivingReportLineItems.length===0) return false;
        for(let item of this.data.ReceivingReportLineItems){
          if(item.quantity<=0 || isNaN(item.quantity)) return false;
          if(item.quantity<item.Product?.ProductSerials?.length) return false;
          if(item.Product?.metadata?.requireSerials){
            if(item.serials?.length!=item.quantity) return false;
          }
          if(item.quantity<(item.serials?.length||0)) return false
        }
        return true
      },
      canEdit(){
        if(this.data.status===0) return true;
        return false;
      },
      ...mapGetters(['getWorkingId', 'getEndpoint', 'getGlobalValue', 'lookupUsername', 'isAllowed', 'serialNaming'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      openViewOCRDUMPDialog(data){
        this.viewOCRDUMPDialog.data = data
        this.viewOCRDUMPDialog.isOpen = true
      },
      closeViewOCRDUMPDialog(){
        this.viewOCRDUMPDialog.data = {}
        this.viewOCRDUMPDialog.isOpen = false
      },
      async updateSupplier(){
        await this.update();
        await this.searchProduct();
      },
      onAddImg(img){
        this.imgFile = img
      },
      async uploadSupplierInvoiceImage(){
        try{
          this.imageUploadLoader = true;
          if(!this.imgFile) throw "No Image Selected.";

          let formData = new FormData();
          formData.append("file", this.imgFile);

          let fileUpload = await axios.post(`${this.getEndpoint}/api/receivingreports/uploadSupplierInvoiceImage/${this.data.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if(fileUpload.data.success){
            this.snack("✅ Image Uploaded.", "success")
            this.data.metadata.media = fileUpload.data.data;
            this.imgFile = null
          }
          else 
            throw "Error: Image was not deleted."

          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.imageUploadLoader = false;
        }
      },
      async deleteSupplierInvoiceImage(img){
        try {
          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/receivingreports/deleteSupplierInvoiceImage`, {url: img.url, rrId: this.data.id})
          if(res.data.success){
            this.snack("✅ Image Deleted.", "success")
            this.data.metadata.media = res.data.data;
          }
          else 
            throw "Error: Image was not deleted."
        } catch (error) {
          this.snackText = error
          this.snackBar = true;
        } finally {
          this.loader = false
        }
      },
      async update(){
        try{
          this.loader = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`, this.data);
          if(res.data.error) throw res.data.error

          this.data = {
            ...this.data,
            ...res.data.data
          };
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async addProductToRR(item){
        try{
          item.loading = true;

          let obj = {
            rrId: this.data.id,
            productId: item.id,
            name: item.name,
            sku: item.sku,
            quantity: 1,
            metadata: {
              regularPrice: parseFloat(item.regularPrice || 0)
            }
          }

          if(item.poId){
            obj = {
              rrId: this.data.id,
              productId: item.productId,
              name: item.Product.name,
              sku: item.Product.sku,
              quantity: 1,
              metadata: {
                regularPrice: parseFloat(item.Product?.regularPrice || 0)
              }
            }
          }

          let found = this.data.ReceivingReportLineItems.find(x => x.productId===obj.productId);
          if(found){
            if(item.poId){
              if(((item.quantity-item.receivedQty)-found.quantity)<=0) throw "Quantity surpasses the amount on the PO and the Already Received Amount."
            }
            found.quantity++;
            await this.updateLineItem(found)
            return;
          }

          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/lineItem/${this.data.id}`, obj);
          if(res.data.error) throw res.data.error

          this.data.ReceivingReportLineItems.push({...res.data.data, loading: false});
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          item.loading = false;
        }
      },
      async removeLineItem(id){
        try{
          let item = this.data.ReceivingReportLineItems.find(x => x.productId===id);
          if(!item) throw "Product not in report";

          item.loading = true;
          this.data = {
            ...this.data,
            ReceivingReportLineItems: this.data.ReceivingReportLineItems
          }

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/deleteLineItem/${this.data.id}`, item);
          if(res.data.error) throw res.data.error

          let i = this.data.ReceivingReportLineItems.findIndex(x => x.productId===id);

          this.data.ReceivingReportLineItems.splice(i, 1);
        }
        catch (error) {
          let item = this.data.ReceivingReportLineItems.find(x => x.productId===id);

          item.loading = false;
          this.data = {
            ...this.data,
            ReceivingReportLineItems: this.data.ReceivingReportLineItems
          }

          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async updateLineItem(item){
        try{
          this.loader = true;

          if(item.quantity<1) {
            item.quantity = 1;
            throw "Quantity must be greater than 0."
          }

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/lineItem/${this.data.id}`, item);
          if(res.data.error){
            item.quantity = 1
            console.log("quantity changed back")
            throw res.data.error
          }

          item = res.data.data;

          this.data = {
            ...this.data,
            ReceivingReportLineItems: this.data.ReceivingReportLineItems
          }
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.searchLoading = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimitForPO?val=${encodeURIComponent(this.productSearchTerm)}&constrain=${encodeURIComponent(this.data.metadata.constrainSearch)}&supplierId=${encodeURIComponent(this.data.supplierId)}`);
            if(res.data.error) throw res.data.error

            let products = res.data.data;

            for(let prod of products){

              prod.loading = false;
            }
            this.productSearchResults = products

            if(products.length===0) throw "No products matching the search criteria."
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.searchLoading = false;
        }
      },
      clearSearch(){
        this.productSearchResults = [];
      },
      openSealDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sealOpen = true;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      openVoidDialog(){
        this.authDialog.sealOpen = false;
        this.authDialog.voidOpen = true;
        this.authDialog.loading = false
        this.authDialog.password = ''
      },
      closeAuthDialog(){
        this.authDialog.voidOpen = false;
        this.authDialog.sealOpen = false;
        this.authDialog.password = ''
      },
      async attemptVoid(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Voided.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async attemptSeal(){
        try{
          this.authDialog.loading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/seal/${this.data.id}`, {...this.authDialog})
          if(res.data.error) throw res.data.error

          await this.getAllData();

          this.snack(`✅ Sealed.`)
          this.closeAuthDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.authDialog.loading = false
        }
      },
      async saveSupplierInvoiceNumber(){
        try{
          this.supplierInvoiceNumberLoading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/supplierInvoiceNumber/${this.data.id}`, {invoiceNumber: this.data.metadata.supplierExternalInvoiceId})
          if(res.data.error) throw res.data.error

          this.snack(`✅ Supplier Invoice Number Saved.`)
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.supplierInvoiceNumberLoading = false
        }
      },
      async saveNote(){
        try{
          this.noteLoading = true
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/note/${this.data.id}`, {note: this.data.metadata.note})
          if(res.data.error) throw res.data.error

          this.snack(`✅ Note Saved.`)
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.noteLoading = false
        }
      },
      async getAllData(){
        try{
          this.loader = true;

          let rr = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
          if(rr.data.error) throw rr.data.error
          this.data = rr.data.data

          console.log(this.data)

          this.data.ReceivingReportLineItems.map(x => {
            return {...x, loading: false}
          });

          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers`);
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data

          this.data.metadata.constrainSearch = this.getGlobalValue("constrainPurchaseOrdersToSupplierItems")==="true";
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      openLineItemSerialsDialog(item){
        this.lineItemSerialsDialog.data = item;
        this.lineItemSerialsDialog.isOpen = true;
        this.lineItemSerialsDialog.other = null;
        this.lineItemSerialsDialog.search = "";
        this.lineItemSerialsDialog.newSerial = ""
        this.lineItemSerialsDialog.filtered = item.serials?[...item.serials]:[]
      },
      closeLineItemSerialsDialog(){
        this.lineItemSerialsDialog.isOpen = false;
        this.lineItemSerialsDialog.data = null;
        this.lineItemSerialsDialog.other = null;
        this.lineItemSerialsDialog.search = "";
        this.lineItemSerialsDialog.newSerial = ""
        this.lineItemSerialsDialog.filtered = []
      },
      async getLineSerials(){
        if(!this.lineItemSerialsDialog.data) return;

        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.lineItemSerialsDialog.data.rrId}/lineSerials/${this.lineItemSerialsDialog.data.productId}`)
        if(res.data.error) throw res.data.error
        this.lineItemSerialsDialog.data.serials = res.data.data

        this.filterLineItemSerials();
      },
      async addLineItemSerial(){
        try{
          this.lineItemSerialsDialog.isLoading = true;

          let item = this.lineItemSerialsDialog.data

          if(!this.lineItemSerialsDialog.newSerial?.trim()){
            throw `Serial cannot be empty.`
          }

          if(item.serials?.length>=item.quantity){
            throw `Max number of ${this.serialNaming.pluralLower} for this line item.`
          }

          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/${item.rrId}/serials`, {productId: item.productId, serial: this.lineItemSerialsDialog.newSerial.trim()})
          if(res.data.error) throw res.data.error

          console.log(res.data)

          await this.getLineSerials(item.id);

          this.lineItemSerialsDialog.newSerial = ""
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.lineItemSerialsDialog.isLoading = false;
        }
      },
      async removeLineItemSerial(serial){
        try{
          this.lineItemSerialsDialog.isLoading = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.lineItemSerialsDialog.data.rrId}/serials`, serial)
          if(res.data.error) throw res.data.error

          await this.getLineSerials(this.lineItemSerialsDialog.data.id);
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.lineItemSerialsDialog.isLoading = false;
        }
      },
      filterLineItemSerials(){
        console.log(this.lineItemSerialsDialog)
        if(!this.lineItemSerialsDialog.data) return;

        if(!this.lineItemSerialsDialog.search){
          this.lineItemSerialsDialog.filtered = [...this.lineItemSerialsDialog.data.serials]
          return;
        }

        this.lineItemSerialsDialog.filtered = this.lineItemSerialsDialog.data.serials.filter(x => x.serial.toLowerCase().includes(this.lineItemSerialsDialog.search.toLowerCase()))
      },
    }
  }
</script>
